import apiService from './index';

export default {
  gatherings: {
    async list(params) {
      const response = apiService.get('/api/pr_gathering/gatherings/', { params });
      return response;
    },
    async setDefaults(params) {
      const response = apiService.post('/api/pr_gathering/gatherings/set_defaults/');
      return response;
    },
    async getSample() {
      const response = apiService.get('/api/pr_gathering/gatherings/get_sample/');
      return response;
    },
    async update(data) {
      const response = apiService.patch(`/api/pr_gathering/gatherings/${data.id}/`, data);
      return response;
    },
  },
};
