import wrappers from '@main/func/wrappers';

export default async function wrapperFactory(wrapperNames, func, idx) {
  if (typeof idx === 'undefined') {
    idx = 0;
  }
  console.log(idx);
  const wrapperPrerequisite = wrapperNames[idx];
  let wrapper;
  let newFunc;
  if (typeof wrapperPrerequisite === 'object') {
    wrapper = wrappers[wrapperPrerequisite.name];
    newFunc = () => wrapper(func, ...wrapperPrerequisite.args);
  } else {
    wrapper = wrappers[wrapperPrerequisite];
    newFunc = () => wrapper(func);
  }
  if (idx === wrapperNames.length - 1) {
    return newFunc();
  }
  idx += 1;
  return wrapperFactory(wrapperNames, newFunc, idx);
}
