<template>
  <modal
    :show="show"
    title="Пример набора"
    @hide="() => $emit('hide')"
    contentClass="p-4 fs-16 overflow-auto"
  >
    <div
      style="white-space: pre-wrap"
      v-if="currentUser && currentUser.blogger_profile"
      v-html="sample"
    ></div>
    <div v-else>
      ⭐ Блогер — korotylka ✍ Темы: Путешествия, Лайфстайл, Скидки
      <br /><br />
      Охваты сторис — 55 897 Охваты постов — 134 234
      <br /><br />
      📈 Статистика аудитории: Женщины — 92% Россия — 85% 25-34 года — 45% Москва — 25%
      <br /><br />
      📈 Отзывы и карточка блогера
      <br /><br />
      Блогер не рекламирует: казино, ставки, пирамиды, эзотерику.
      <br /><br />
      Форматы для личных блогов ➡ Сторис 60 сек. — 15 000 ₽Обзор/распаковка товара/услуги
      длительность ~4 сториз.
      <br /><br />
      Форматы для коммерческих аккаунтов ➡ Сторис 60 сек. — 15 000 ₽Обзор/распаковка товара/услуги
      длительность ~4 сториз.
      <br /><br />
      Предложения о рекламе присылайте на PR-аккаунт: korotylka_rek
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import PrTypeDisplayableMixin from '@main/mixins/statistic/PrTypeDisplayableMixin.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import prGathering from '@main/api/prGathering';

export default {
  props: {
    show: Boolean,
  },
  data: () => ({
    sample: null,
  }),
  computed: {
    ...mapState(['currentUser', 'bloggerOptions']),
    minPrice() {
      if (!this.currentUser.blogger_profile.pr_types.length) {
        return;
      }
      return this.currentUser.blogger_profile.pr_types
        .reduce((t, n) => {
          return n <= t ? n : t;
        })
        .uPrice.toLocaleString()
        .replaceAll(',', ' ');
    },
    currentAudience() {
      const { choices } = this.bloggerOptions.actions.POST.audiences.child.children.age_group;
      const values = choices.filter(
        (val) => val.value === this.currentUser.blogger_profile.main_audience.age_group
      );
      return values[0].display_name;
    },
  },
  async mounted() {
    this.sample = await prGathering.gatherings.getSample();
  },
  components: {
    Modal,
  },
};
</script>

<style lang="css" scoped></style>
