<template>
  <div class="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
    <strong
      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      class="fs-16 mr-2 mr-sssm-0"
      >{{ gathering.name }}</strong
    >
    <b-form-group class="mr-2 mr-sssm-0" label="День месяца"
      ><b-form-select
        class="fs16"
        :disabled="disabled"
        v-model="gathering.day"
        style="width: 50px !important; padding: 3px !important; padding-left: 10px !important"
        :options="months"
      ></b-form-select
    ></b-form-group>
    <b-form-group style="min-width: 100px !important" label="Время" class="mr-md-5">
      <b-form-select
        class="fs16 text-center"
        :disabled="disabled"
        :value="currentHour"
        @input="(val) => setTimeValue(val, 0)"
        style="width: 40px !important; padding: 3px !important; padding-left: 10px !important"
        :options="hours"
      ></b-form-select>
      <span class="mx-2">:</span>
      <b-form-select
        class="fs16 text-center"
        :disabled="disabled"
        @input="(val) => setTimeValue(val, 1)"
        :value="currentMinute"
        style="width: 40px !important; padding: 3px !important; padding-left: 10px !important"
        :options="minutes"
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import PrGatheringItemMixin from '@main/mixins/PrGatheringItemMixin.vue';

export default {
  mixins: [PrGatheringItemMixin],
};
</script>

<style lang="scss" scoped>
::v-deep .col-form-label {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
