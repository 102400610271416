<template>
  <div>
    <h2 class="my-4 gathering__h2">Автонаборы на рекламу в чатах</h2>
    <div
      v-if="!currentUser || !currentUser.blogger_profile || !currentUser.blogger_profile.filled"
      class="hovering__pad d-flex flex-wrap justify-content-between flex-row p-sm-4 p-3 mb-5 align-items-center"
    >
      <h2 class="gathering__h2 mb-4 mb-llg-0">
        ❗Загрузите вашу карточку на сайт, чтобы публиковать автонаборы
      </h2>
      <b-button class="w-mobile-100" :to="{ name: 'fill_statistics' }" size="lg" variant="yellow"
        >Загрузить карточку блогера</b-button
      >
    </div>
    <div
      id="ads-gat-demo"
      class="hovering__pad d-flex flex-column-reverse flex-lg-row justify-content-between p-2 p-sm-4 mb-5"
    >
      <div
        style="line-height: 27px !important"
        class="mb-5 mb-lg-0 w-md-50 m-2 mr-lg-5 gathering__info--block"
      >
        <div class="mb-5">
          <h1 style="font-weight: 500; line-height: 32.4px" class="mt-2 mb-4">
            Бесплатно разместите ваши наборы на рекламу в чатах, прикрепленных к
            <a target="_blank" href="t.me/easyprbot">@easyprbot</a>.
          </h1>
          <p class="fs-16 mt-2 mb-4">
            Размещение полностью автоматическое, просто укажите день каждого месяца и время
            размещения.
          </p>
          <p style="background-color: #fffce3" class="fs-16 mt-2 p-2 p-sm-3">
            Первый автонабор будет размещён сразу после того, как ваша карточка пройдет модерацию.
            Вы можете выбрать даты и время автонаборов уже сейчас.<br />
            <br />
            Если блогер занесен в <strong>черный список</strong> сайта, то автонаборы на рекламу
            размещаться не будут.
          </p>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-between">
          <b-button
            class="fs-16 mx-auto mx-sm-0"
            @click="showExample = true"
            variant="outline-default"
            >Посмотреть пример автонабора</b-button
          >
          <b-button
            :to="{ name: 'reviews' }"
            class="fs-16 mx-auto mt-4 mt-ssm-0 mx-ssm-0"
            variant="outline-default"
            >Вернуться к отзывам</b-button
          >
          <!--b-button class="w-100 mt-5 d-block d-sm-none" v-hideon="'ads-gat-demo'" variant="link"
            >Больше не показывать</!--b-button
          -->
        </div>
      </div>
      <div class="w-md-50 m-2 ml-lg-5 gathering__info--block">
        <div class="d-flex flex-row justify-content-between flex-wrap mb-4">
          <div>
            <img
              class="mr-2 d-inline-block"
              style="width: 35px; height: 23px"
              src="@main/assets/img/icons/ut_icon.png"
            />
            <span>Как работают наборы на рекламу?</span>
          </div>
          <b-button class="d-none d-lg-block" v-hideon="'ads-gat-demo'" variant="link"
            >Больше не показывать</b-button
          >
        </div>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/dgJJckBfqX0"
          allowfullscreen
        ></b-embed>
      </div>
    </div>
    <div class="mb-5">
      <div
        style="line-height: 27px !important; border: 1px solid #fadb67"
        class="hovering__pad functional__block mr-md-5 p-2 p-ssm-4 align-top"
      >
        <div class="m-2">
          <h3 style="line-height: 27px; font-size: 20px" class="mb-4">
            +1 автонабор в день при покупке подписки
          </h3>
          <div>
            <GatheringItemPremium
              v-for="gathering in gatherings.filter((val) => val.publication_scheme)"
              :gathering="gathering"
              :disabled="
                !currentUser ||
                !currentUser.blogger_profile ||
                currentUser.profile.gathering ||
                !currentUser.services_subscriber
              "
              v-model="gatherings[gatherings.indexOf(gathering)]"
              :key="`AHpremdnbua1d${gathering.id}wj9981bcmo`"
              :ref="`gathering_prem${gathering.id}`"
            />
          </div>
          <div class="mb-4 mt-2">
            <span
              :class="[
                currentUser && currentUser.profile.gathering && currentUser.services_subscriber
                  ? 'gathering__indicator__green'
                  : 'gathering__indicator__red',
              ]"
              class="gathering__indicator"
              >{{
                currentUser && currentUser.profile.gathering && currentUser.services_subscriber
                  ? 'Автонаборы активны'
                  : 'Автонаборы неактивны'
              }}</span
            ><b-button @click="showExample = true" class="ml-xsm-2 ml-ssm-3" variant="link"
              >Посмотреть пример набора</b-button
            >
          </div>
          <b-button
            v-if="!currentUser || !currentUser.services_subscriber"
            href="https://sub.easyprbot.com/"
            target="_blank"
            variant="yellow"
            style="white-space: normal; text-overflow: unset; overflow: hidden"
            class="w-100 fs-16"
            >Купить подписку и запустить автонаборы</b-button
          >
          <b-button
            v-else-if="
              currentUser && currentUser.services_subscriber && currentUser.profile.gathering
            "
            @click="() => setGathering(false)"
            variant="outline-default"
            class="w-100 fs-16"
            :disabled="loading"
            >Остановить автонаборы</b-button
          >
          <b-button
            v-else-if="
              currentUser && currentUser.services_subscriber && !currentUser.profile.gathering
            "
            @click="() => setGathering(true)"
            :variant="currentUser && currentUser.blogger_profile ? 'yellow' : 'secondary'"
            :disabled="!currentUser || !currentUser.blogger_profile || loading"
            class="w-100 fs-16"
            >Сохранить и запустить автонаборы</b-button
          >
          <p style="font-weight: 500" class="text-center mt-2 fs-16">
            Осталось автонаборов сегодня:
            {{ gatherings.filter((val) => val.publication_scheme && !val.sent).length }}
          </p>
        </div>
      </div>
      <div
        style="line-height: 27px !important"
        class="hovering__pad functional__block mt-3 mt-lg-0 mr-md-5 p-2 p-ssm-4 align-top"
      >
        <div class="m-2">
          <h3 style="line-height: 27px; font-size: 20px" class="mb-4">
            Бесплатное авторазмещение автонаборов на рекламу 3 раза в месяц
          </h3>
          <p class="fs-16 mb-4">Просто выберите день каждого месяца и время 👇</p>
          <div>
            <GatheringItem
              v-for="gathering in gatherings.filter((val) => !val.publication_scheme)"
              :gathering="gathering"
              :disabled="
                !currentUser || !currentUser.blogger_profile || currentUser.profile.gathering
              "
              v-model="gatherings[gatherings.indexOf(gathering)]"
              :key="`AHd761d${gathering.id}wj9981bcmo`"
            />
          </div>
          <div class="mb-4 mt-2">
            <span
              :class="[
                currentUser &&
                currentUser.profile.gathering &&
                this.gatherings.filter((val) => !val.publication_scheme).some((val) => val.active)
                  ? 'gathering__indicator__green'
                  : 'gathering__indicator__red',
              ]"
              class="gathering__indicator"
              >{{
                currentUser &&
                currentUser.profile.gathering &&
                this.gatherings.filter((val) => !val.publication_scheme).some((val) => val.active)
                  ? 'Автонаборы активны'
                  : 'Автонаборы неактивны'
              }}</span
            ><b-button @click="showExample = true" class="ml-xsm-2 ml-ssm-3" variant="link"
              >Посмотреть пример набора</b-button
            >
          </div>
          <b-button
            v-if="currentUser && currentUser.profile.gathering"
            @click="() => setGathering(false)"
            variant="outline-default"
            :disabled="loading"
            class="w-100 fs-16"
            >Остановить наборы</b-button
          >
          <b-button
            v-else-if="!currentUser || !currentUser.profile.gathering"
            @click="() => setGathering(true)"
            :disabled="!currentUser || !currentUser.blogger_profile || loading"
            :variant="currentUser && currentUser.blogger_profile ? 'yellow' : 'secondary'"
            class="w-100 fs-16"
            >Сохранить и запустить наборы</b-button
          >
          <p style="font-weight: 500" class="text-center mt-2 fs-16">
            Осталось наборов в этом месяце:
            {{ gatherings.filter((val) => !val.publication_scheme && !val.sent).length }}
          </p>
        </div>
      </div>
      <div
        style="
          line-height: 27px !important;
          border: 1px solid #fadb67;
          max-width: 348px;
          width: 100%;
        "
        class="hovering__pad mt-3 p-2 p-ssm-4 d-inline-block mt-lg-0 bot-block"
      >
        <div class="d-flex flex-column align-items-center">
          <img src="@main/assets/botfather.png" alt="" />
          <div class="text-center mt-3" style="font-size: 20px">
            Стать блогером<br />
            в
            <b-button
              variant="link"
              href="t.me/easyprbot"
              target="_blank"
              style="font-size: 20px; display: contents"
              >телеграм-боте Easyprbot</b-button
            >
          </div>
          <b-button
            @click="toggleBotPlacement"
            class="fs-16 mt-4"
            :variant="
              currentUser &&
              currentUser.blogger_profile &&
              currentUser.blogger_profile.bot_placement &&
              currentUser.blogger_profile.filled &&
              [2, 6].includes(Number(currentUser.blogger_profile.moderation_status))
                ? 'outline-default'
                : 'yellow'
            "
            >{{
              currentUser && currentUser.services_subscriber && currentUser.blogger_profile
                ? currentUser.blogger_profile.bot_placement &&
                  currentUser.blogger_profile.filled &&
                  [2, 6].includes(Number(currentUser.blogger_profile.moderation_status))
                  ? 'Остановить размещение в боте'
                  : 'Запустить размещение в боте'
                : 'Купить подписку и разместиться'
            }}</b-button
          >
          <div class="mb-4 mt-3">
            <span
              :class="[
                currentUser &&
                currentUser.blogger_profile &&
                currentUser.blogger_profile.bot_placement &&
                currentUser.blogger_profile.filled &&
                [2, 6].includes(Number(currentUser.blogger_profile.moderation_status))
                  ? 'gathering__indicator__green'
                  : 'gathering__indicator__red',
              ]"
              class="gathering__indicator"
              >{{
                currentUser &&
                currentUser.blogger_profile &&
                currentUser.blogger_profile.bot_placement &&
                currentUser.blogger_profile.filled &&
                [2, 6].includes(Number(currentUser.blogger_profile.moderation_status))
                  ? 'Размещение в боте активно'
                  : 'Размещение в боте неактивно'
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <AdvertisingGatheringExampleModal @hide="showExample = false" :show="showExample" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import wrappers from '@main/func/wrappers';
import wrapperFactory from '@main/func/wrapperFactory';
import GatheringItemPremium from '@main/components/advertising_gathering/GatheringItemPremium.vue';
import GatheringItem from '@main/components/advertising_gathering/GatheringItem.vue';
import AdvertisingGatheringExampleModal from '@main/components/reusable/modals/AdvertisingGatheringExampleModal.vue';
import prGathering from '@main/api/prGathering';
import auth from '@main/api/auth';
import bloggers from '@main/api/bloggers';

export default {
  components: {
    AdvertisingGatheringExampleModal,
    GatheringItem,
    GatheringItemPremium,
  },
  data: () => ({
    showExample: false,
    gatherings: null,
    loading: false,
  }),
  methods: {
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    ...mapActions(['loadUser']),
    async setGathering(val) {
      wrapperFactory(
        [
          { name: 'requiresBloggerProfile', args: [!val] },
          'requiresBloggerProfileModerated',
        ].reverse(),
        async () => {
          this.loading = true;
          await auth.updateMe({
            id: 36260,
            profile: { ...this.lodash.cloneDeep(this.currentUser.profile), gathering: val },
            blogger_profile: {
              ...this.lodash.cloneDeep(this.currentUser.blogger_profile),
              hidden: false,
            },
          });
          await this.loadUser();
          await this.getGatherings();
          this.loading = false;
        }
      );
    },
    async toggleBotPlacement() {
      wrapperFactory(
        ['requiresSub', 'requiresBloggerProfile', 'requiresBloggerProfileModerated'].reverse(),
        async () => {
          await bloggers.toggleBotPlacement();
          await this.loadUser();
        }
      );
    },
    async getGatherings() {
      try {
        if (!this.currentUser) {
          const gatherings = await prGathering.gatherings.getSample();
          this.gatherings = gatherings.results;
          return;
        }
        let response = await prGathering.gatherings.list();
        response = response.results;
        if (!response.length) {
          response = await prGathering.gatherings.setDefaults();
        }
        this.gatherings = response;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapState(['currentUser']),
  },
  async mounted() {
    await this.getGatherings();
  },
};
</script>

<style scoped>
.functional__block {
  max-width: 450px;
  max-height: 800px;
  display: inline-block;
}

@media (max-width: 650px) {
  .functional__block {
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .gathering__info--block {
    width: 50% !important;
  }
}

.gathering__indicator {
  padding-left: 14px;
  position: relative;
  font-size: 15px;
  line-height: 16.5px;
}

.gathering__indicator__green::before {
  width: 8px !important;
  min-width: 8px !important;
  height: 8px !important;
  content: '';
  position: absolute;
  top: 24%;
  left: 0px;
  border-radius: 10px;
  background: green;
}

.gathering__indicator__red::before {
  width: 8px !important;
  min-width: 8px !important;
  height: 8px !important;
  content: '';
  position: absolute;
  top: 24%;
  left: 0px;
  border-radius: 10px;
  background: red;
}

.gathering__item:not(:last-child) {
  border-bottom: 1px solid #d5dfe4;
}

.gathering__h2 {
  font-size: 24px;
}

@media (max-width: 768px) {
  .gathering__h2 {
    font-size: 17.9px;
    text-align: left;
  }
}
@media (max-width: 650px) {
  .bot-block {
    width: 100%;
    max-width: none !important;
  }
}
</style>
